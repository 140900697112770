import Q from "q";
import {
  UpdateRequest,
  UnsubscribeRequest,
  StatusRequest,
} from "../stubs/migrations/services/subscribers_pb";
import { ManageClient } from "../stubs/migrations/services/subscribers_grpc_web_pb";

const host = process.env.GATSBY_BACKEND_URL;
const client = new ManageClient(host);

export function getSubscriptionStatus(email) {
  const request = new StatusRequest();
  request.setEmail(email);

  const d = Q.defer();
  client.getState(request, {}, (err, response) => {
    if (err !== null) d.reject(err);
    else d.resolve(response.toObject());
  });
  return d.promise;
}

export function subscribe(email) {
  if (email === null || email === "") {
    throw Error("missing email");
  }
  const request = new UpdateRequest();
  request.setEmailAddress(email);
  request.setWantsAlertEmails(true);

  const d = Q.defer();
  client.update(request, {}, (err, response) => {
    if (err !== null) d.reject(err);
    else d.resolve(response.toObject());
  });
  return d.promise;
}

export function unsubscribe(eid) {
  if (eid === null || eid === "") {
    throw Error("missing eid");
  }

  const request = new UnsubscribeRequest();
  request.setEid(eid);

  const d = Q.defer();
  client.unsubscribe(request, {}, (err, response) => {
    if (err !== null) d.reject(err);
    else d.resolve(response.toObject());
  });
  return d.promise;
}

// export function getNetSpread(issuerId) {
//   const request = new NetSpreadRequest();
//   request.setIssuerId(issuerId);

//   const d = Q.defer();
//   client.getNetSpread(request, {}, (err, response) => {
//     if (err !== null) d.reject(err);
//     else d.resolve(response.toObject());
//   });

//   return d.promise;
// }
