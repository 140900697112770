/**
 * @fileoverview gRPC-Web generated client stub for migrations.services.subscribers
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.3.1
// 	protoc              v3.20.1
// source: migrations/services/subscribers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.migrations = {};
proto.migrations.services = {};
proto.migrations.services.subscribers = require('./subscribers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.migrations.services.subscribers.ManageClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.migrations.services.subscribers.ManagePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.migrations.services.subscribers.UpdateRequest,
 *   !proto.migrations.services.subscribers.UpdateResponse>}
 */
const methodDescriptor_Manage_Update = new grpc.web.MethodDescriptor(
  '/migrations.services.subscribers.Manage/Update',
  grpc.web.MethodType.UNARY,
  proto.migrations.services.subscribers.UpdateRequest,
  proto.migrations.services.subscribers.UpdateResponse,
  /**
   * @param {!proto.migrations.services.subscribers.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.migrations.services.subscribers.UpdateResponse.deserializeBinary
);


/**
 * @param {!proto.migrations.services.subscribers.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.migrations.services.subscribers.UpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.migrations.services.subscribers.UpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.migrations.services.subscribers.ManageClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/migrations.services.subscribers.Manage/Update',
      request,
      metadata || {},
      methodDescriptor_Manage_Update,
      callback);
};


/**
 * @param {!proto.migrations.services.subscribers.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.migrations.services.subscribers.UpdateResponse>}
 *     Promise that resolves to the response
 */
proto.migrations.services.subscribers.ManagePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/migrations.services.subscribers.Manage/Update',
      request,
      metadata || {},
      methodDescriptor_Manage_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.migrations.services.subscribers.StatusRequest,
 *   !proto.migrations.services.subscribers.StatusResponse>}
 */
const methodDescriptor_Manage_GetState = new grpc.web.MethodDescriptor(
  '/migrations.services.subscribers.Manage/GetState',
  grpc.web.MethodType.UNARY,
  proto.migrations.services.subscribers.StatusRequest,
  proto.migrations.services.subscribers.StatusResponse,
  /**
   * @param {!proto.migrations.services.subscribers.StatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.migrations.services.subscribers.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.migrations.services.subscribers.StatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.migrations.services.subscribers.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.migrations.services.subscribers.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.migrations.services.subscribers.ManageClient.prototype.getState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/migrations.services.subscribers.Manage/GetState',
      request,
      metadata || {},
      methodDescriptor_Manage_GetState,
      callback);
};


/**
 * @param {!proto.migrations.services.subscribers.StatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.migrations.services.subscribers.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.migrations.services.subscribers.ManagePromiseClient.prototype.getState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/migrations.services.subscribers.Manage/GetState',
      request,
      metadata || {},
      methodDescriptor_Manage_GetState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.migrations.services.subscribers.UnsubscribeRequest,
 *   !proto.migrations.services.subscribers.UnsubscribeResponse>}
 */
const methodDescriptor_Manage_Unsubscribe = new grpc.web.MethodDescriptor(
  '/migrations.services.subscribers.Manage/Unsubscribe',
  grpc.web.MethodType.UNARY,
  proto.migrations.services.subscribers.UnsubscribeRequest,
  proto.migrations.services.subscribers.UnsubscribeResponse,
  /**
   * @param {!proto.migrations.services.subscribers.UnsubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.migrations.services.subscribers.UnsubscribeResponse.deserializeBinary
);


/**
 * @param {!proto.migrations.services.subscribers.UnsubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.migrations.services.subscribers.UnsubscribeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.migrations.services.subscribers.UnsubscribeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.migrations.services.subscribers.ManageClient.prototype.unsubscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/migrations.services.subscribers.Manage/Unsubscribe',
      request,
      metadata || {},
      methodDescriptor_Manage_Unsubscribe,
      callback);
};


/**
 * @param {!proto.migrations.services.subscribers.UnsubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.migrations.services.subscribers.UnsubscribeResponse>}
 *     Promise that resolves to the response
 */
proto.migrations.services.subscribers.ManagePromiseClient.prototype.unsubscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/migrations.services.subscribers.Manage/Unsubscribe',
      request,
      metadata || {},
      methodDescriptor_Manage_Unsubscribe);
};


module.exports = proto.migrations.services.subscribers;

