import React, { useState } from "react";

import {
  // makeStyles,
  withStyles,
  createTheme,
  ThemeProvider,
  withTheme,
  useTheme,
} from "@material-ui/core/styles";
import { Button, TextField, Link } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import MigrationsFooterLogo from "../assets/img/Migrations_logo.svg";
import DataArchitectureLogo from "../assets/img/icons/web.svg";
import DataArchitectureDeck from "../assets/media/Migrations_ml Architecture February 2023.pdf"
import { Helmet } from "react-helmet";

import StickyBox from "react-sticky-box";
import TopAppBar from "./topAppBar";
import { subscribe } from "./landing/emailAlert";

const styles = {
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100vh",
    backgroundColor: "rgb(18,34,61)",
  },
  root_input: {
    '&$focused $notchedOutline': {
       borderColor: 'orange'
    }
  },
  focused: {
    '&$focused $notchedOutline': {
      borderColor: 'orange'
   }
  },
  notchedOutline: {}
};

// const theme = darkBaseTheme;
const theme = createTheme({
  palette: {
    type: "dark",
  },
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application!
    }
  }
});

const handleClick = () => {
  window.open(DataArchitectureDeck)
};

const PageBase = (props) => {
  const { classes, children } = props;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StickyBox className="stickybox" offsetTop={0} offsetBottom={20}>
        <div className="stickybox-contents">
          <Button className="btn btn-primary" onClick={handleClick}><p>View Data Architecture</p><img src={DataArchitectureLogo} height="30" /></Button>
        </div>
      </StickyBox>
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Migrations.ML</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="canonical" href="https://migrations.ml" />
          <link href="https://use.fontawesome.com/releases/v5.15.4/css/svg-with-js.css" rel="stylesheet"></link>
        </Helmet>
        <div>
          <TopAppBar />
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

const FooterBase = () => {
  const [email, setEmail] = useState(null);
  const [feedback, setFeedback] = useState('');

  const subscribeToEmailAlert = () => {
    console.log(email)
    if (email !== null) {
      subscribe(email).then(() => {
        setFeedback("You are now Subscribed!");
        setTimeout(() => setFeedback(""), 2500);
      })
    } else {
      setFeedback("Please Add your email");
    }
  }

  return (
    <footer className="bg-dark text-inverse">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <img className="mb-4" src={MigrationsFooterLogo} width="200px" alt="" />
              <p className="mb-4">© 2023 Migrations.ml <br className="d-none d-lg-block" />All rights reserved.</p>
              <nav className="nav social social-white">
                <a href="https://ca.linkedin.com/company/migrations-ml"><i className="uil uil-linkedin"></i></a>
                <a href="https://www.youtube.com/@migrationsml4087"><i className="uil uil-youtube"></i></a>
              </nav>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Get in Touch</h4>
              <a href="mailto:info@migrations.ml">info@migrations.ml</a><br /> +1 (647) 239-0101
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Free Daily Credit Insights</h4>
              <p className="mb-5">Subscribe and get daily updates on the biggest credit movements in the market.</p>
              <div className="newsletter-wrapper">
                <div id="mc_embed_signup2">
                  <div id="mc_embed_signup_scroll2">
                    <div className="mc-field-group input-group form-floating">
                      <TextField 
                        size="small" 
                        id="standard-basic" 
                        variant="outlined" 
                        label="Email Address" 
                        placeholder="e.g. yourname@gmail.com"
                        onChange={(e) => setEmail(e.target.value)}
                        // value={email}
                      />
                      <Button
                        variant="contained" 
                        size="large"
                        type="submit"
                        className="btn btn-primary"
                        onClick={subscribeToEmailAlert}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <p>{feedback}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const Footer = withTheme(FooterBase);

export default withStyles(styles)(PageBase);
