import React, { useState } from "react";
import { 
  AppBar, 
  Toolbar, 
  Link, 
  Button, 
  Grid, 
  Hidden, 
  Box,
  ClickAwayListener,
  Menu,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WordLogo from "../images/wordLogo.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/pro-solid-svg-icons";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "rgb(26, 43, 80)",
  },
  dropdown: {
    backgroundColor: "rgb(26, 43, 80)",
    paddingBottom: "1em",
    zIndex: 1001,
    position: 'relative'
  },
  dropdownButton: {
    margin: "0.5rem", 
    width: "10em",
    '&::after': {
      content: '\e900',
      color: '#97d700'
    }
  },
}));

const TopAppBar = (props) => {
  const classes = useStyles();
  const [dropdownHidden, setDropdownHidden] = useState(true); 

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log('Clicked');
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (path) => {
    setAnchorEl(null);
    console.log('Handle Close')
    console.log(path)

    if (path !== null) {
      window.location.href = path;
    }
  };

  const handleCloseAbout = () => {
    handleClose(null);
  };

  const handleCloseTeam = () => {
    handleClose('/about/team')
  };

  const handleCloseCareers = () => {
    handleClose('/about/careers')
  };

  return (
    <ClickAwayListener onClickAway={() => setDropdownHidden(true)}>
      <AppBar position="static" className="MuiAppBar">
        <div className="container flex-lg-row flex-nowrap align-items-center p-0">
        <Toolbar className={classes.toolbar}>
          <Link href="/" rel="noopener noreferrer">
            <img src={WordLogo} alt="Migrations.ML" height="25rem" />
          </Link>

          <Hidden xsDown>
            <Button
              href="/product"
              style={{ marginLeft: "auto", marginRight: "0.5rem" }}
            >
              Product
            </Button>
            <Button style={{ margin: "0.5rem" }}
              id="about-button"
              aria-controls={open ? 'about-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              About Us
            </Button>
            <Menu
              id="about-menu"
              aria-labelledby="about-button"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={open}
              onClose={handleCloseAbout}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleCloseTeam}>Team</MenuItem>
              <MenuItem onClick={handleCloseCareers}>Careers</MenuItem>
            </Menu>
            <Button
              href={process.env.GATSBY_APP_URL}
              style={{ margin: "0.5rem" }}
              variant="outlined"
              className="btn-primary-no-fill btn rounded-pill"
            >
              Login
            </Button>
          </Hidden>

          <Hidden smUp>
            <Button onClick={() => setDropdownHidden(!dropdownHidden)}>
              {/* <FontAwesomeIcon
                fixedWidth
                icon={faBars}
                size="lg"
              /> */}
              {dropdownHidden ? 
                <MenuIcon />
                :
                <MenuOpenIcon />
              }
            </Button>
          </Hidden>
        </Toolbar>
        
        <Hidden smUp>
          <Box hidden={dropdownHidden}>
            <Grid 
              container 
              direction="column" 
              alignContent="center" 
              className={classes.dropdown}
            >
              <Button href="/product" className={classes.dropdownButton}>
                Product
              </Button>
              <Button href="/about/team" className={classes.dropdownButton}>
                Team
              </Button>
              <Button href="/about/careers" className={classes.dropdownButton}>
                Careers
              </Button>
              <Button
                href={process.env.GATSBY_APP_URL}
                variant="outlined"
                className="btn-primary-no-fill btn rounded-pill"
              >
                Login
              </Button>
            </Grid>
          </Box>
        </Hidden>
        </div>
      </AppBar>
    </ClickAwayListener>
  );
};

export default TopAppBar;
